<template>
  <div v-if="info">
    <button>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m14.718.075.707.707L8.707 7.5l6.718 6.718-.707.707L8 8.207l-6.718 6.718-.707-.707L7.293 7.5.575.782l.707-.707L8 6.793 14.718.075Z"
          fill-rule="evenodd"
        />
      </svg>
    </button>
    <div class="person person-info">
      <p class="first">{{info.first}}</p>
      <p class="last">{{info.last}}</p>
      <p class="department" v-html="info.department" />
    </div>
    <div class="person person-details">
      <div>
        <p class="first">Connect With<br /><span>{{info.first}}</span></p>
        <p v-if="info.office" class='contact phone'>{{info.office}}</p>
        <p v-if="info.mobile" class='contact phone'>{{info.mobile}}</p>
        <a v-if="info.email" class='contact email' target='_blank' :href='`mailto:${info?.email?.toLowerCase()}`'>{{info.email}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Person",
  props: {
    info: Object,
  },
};
</script>

<style>
</style>