<template>
  <div class='template'>    
      <transition name="fade" appear>
      <article class="images" :class="[people.length >= 6 ? 'more-people' : '']">
          <div v-for="image in people.length >= 6 ? 10 + people.length - 4 : 10" 
                @click="activePerson = activePerson === image ? -1 : image" 
                :key="image" 
                :class="[
                    activePerson === image ? 'active' : '', 
                    image === 8 ? 'copy-slide' : '', 
                    image === 6 ? 'title-slide' : 'info-tile', 
                    personSlides.includes(image) ? 'person-slide': '', 
                    imageSlides.includes(image) ? 'image-slide': '', 
                    isPersonImageSlide(image) ? 'person-image-slide' : ''
                ]" 
                :style="getImageSlide(image)"> 
            <transition name="slide" appear>
                <div v-if="image === 1">
                        <img src="@/assets/images/placeholder_logo_car-asphalt.png" alt="" class='track-logo'>    
                </div>
                <div v-else-if="image === 2">
                    <div>
                        <h1>{{page.title}}</h1>
                        <AttachmentUI flipped :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="videoVisible = true"/>
                    </div>
                </div>
                <div @click.stop v-else-if="image === 8">
                    <div v-html="copy" />                        
                </div>
                <div @click.stop v-else-if="personSlides.includes(image)">
                    <transition name="slide" appear>
                        <Person :info="getSlideInfo(image)"/>
                    </transition>
                </div>
                <div v-else-if="imageSlides.includes(image)"  />
                <div v-else />
            </transition>
          </div>              
      </article>        
      </transition>
     <div class="linear-holder">
        <LinearButton :to="page?.previous" previous/>
        <LinearButton :to="page?.next"/>
    </div> 
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>
<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
import Person from '@/components/Person';
export default {
    name:'Meet the Team',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        Person,
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },    
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:2, 
            personSlides:[3,5,9,6,7,11,12,13,14,15,16,17,18],
            imageSlides:[4,7,10],
            activePerson : -1
        }
    },
    computed: {  
        collage() {
            let images = [];
            if(this.page.background) {
                images.push({
                    file:this.page.background,
                    title:this.page.title
                });
            }
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },      
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },
        copy() {
            let copy = this.page?.copy;            
            return copy.replace(/\[.*?\]/g, "");
        },
        people() {
            let people = this.parseShortCode(this.page?.copy);
            console.log('people', people)
            return people.slice(0, 15);   //limits up to 6
        }
    },
    methods : {
        isPersonImageSlide(slideIndex) {
            if(this.imageSlides.includes(slideIndex) && this.personSlides.includes(slideIndex)) {
                const imageSlideIndex = this.imageSlides.indexOf(slideIndex);
                const perseonSlideIndex = this.personSlides.indexOf(slideIndex);
                if(this.collage?.length > imageSlideIndex && this.stats?.length > perseonSlideIndex) {
                    return true;
                }
            }
            return false;
        },
        getImageSlide(slideIndex){
            if(this.personSlides.includes(slideIndex)) {
                const imageSlideIndex = this.personSlides.indexOf(slideIndex);
                if(this.people.length > imageSlideIndex) {
                    const person = this.people[imageSlideIndex];
                    const first = person.first?.toLowerCase().substr(0, 1).trim();
                    const last = person.last?.toLowerCase().trim().split(" ").join("");
                    return{
                        backgroundImage:`url('/reps/${first}${last}.jpg')`,
                    }
                }
            }
            if(this.imageSlides.includes(slideIndex)) {
                const imageSlideIndex = this.imageSlides.indexOf(slideIndex);
                if(this.collage.length > imageSlideIndex) {
                    const collage = this.collage[imageSlideIndex];
                    return {
                        backgroundImage:`url(${collage.file})`
                    };
                }
            }
        },
        getSlideInfo(slideIndex){
            let info = undefined;
            if(this.personSlides.includes(slideIndex)) {                
                const perseonSlideIndex = this.personSlides.indexOf(slideIndex);
                if(this.people.length > perseonSlideIndex) {
                    info = {};
                    const person = this.people[perseonSlideIndex];
                    if(person.office !== '' && person.office !== '000-000-0000') {
                        info.office = person.office;
                    }
                    if(person.mobile !== '' && person.mobile !== '000-000-0000') {
                        info.mobile = person.mobile;
                    }
                    if(person.email !== '' && person.email !== 'email@email.com') {
                        info.email = person?.email?.toLowerCase();
                    }
                    info.first = person.first;
                    info.last = person.last;
                    info.department = person.department;
                }
            }
            return info;
        },
        getSlide(slideIndex){
            if(this.personSlides.includes(slideIndex)) {
                const perseonSlideIndex = this.personSlides.indexOf(slideIndex);
                if(this.people.length > perseonSlideIndex) {
                    const person = this.people[perseonSlideIndex];
                    let office = '';
                    if(person.office !== '' && person.office !== '000-000-0000') {
                        office = `<p class='contact phone'>${person.office}</p>`;
                    }
                    let mobile = '';
                    if(person.mobile !== '' && person.mobile !== '000-000-0000') {
                        mobile = `<p class='contact phone'>${person.mobile}</p>`;
                    }
                    let email = '';
                    if(person.email !== '' && person.email !== 'email@email.com') {
                        email = `<a class='contact email' target='_blank' href='mailto:${person?.email?.toLowerCase()}'>${person.email}</a>`;
                    }
                    
                    return `
                        <div>
                            <button>
                                <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m14.718.075.707.707L8.707 7.5l6.718 6.718-.707.707L8 8.207l-6.718 6.718-.707-.707L7.293 7.5.575.782l.707-.707L8 6.793 14.718.075Z" fill-rule="evenodd"/>
                                </svg>
                            </button>
                            <div class='person person-info'>
                                <p class="first">${person.first}</p>
                                <p class="last">${person.last}</p>
                                <p class="department">${person.department}</p>
                            </div>
                            <div class='person person-details'>
                                <div>
                                    <p class='first'>Connect With<br/><span>${person.first}</span></p>
                                    ${office}
                                    ${mobile}
                                    ${email}
                                </div>
                            </div>
                        </div>
                    `;
                }
            }
            return "";
        },
        parseShortCode(shortCode) {
            const shortcodes = [];     
            const rawShortCodes = shortCode.match(/[\w-]+=".+?"/g);
            for (let index = 0; index < rawShortCodes?.length; index += 6) {
                const shortcode = {};
                /*
                    attributes are unordered but rely on the correct naming
                    first, last, department, office, mobile, email            
                */               
                const element1 = rawShortCodes[index].match(/([\w-]+)="(.+?)"/);
                const element2 = rawShortCodes[index + 1].match(/([\w-]+)="(.+?)"/);
                const element3 = rawShortCodes[index + 2].match(/([\w-]+)="(.+?)"/);
                const element4 = rawShortCodes[index + 3].match(/([\w-]+)="(.+?)"/);
                const element5 = rawShortCodes[index + 4].match(/([\w-]+)="(.+?)"/);
                const element6 = rawShortCodes[index + 5].match(/([\w-]+)="(.+?)"/);
                shortcode[element1[1]] = element1[2];
                shortcode[element2[1]] = element2[2];
                shortcode[element3[1]] = element3[2];
                shortcode[element4[1]] = element4[2];
                shortcode[element5[1]] = element5[2];
                shortcode[element6[1]] = element6[2];
                shortcodes.push(shortcode);
            }            
            return shortcodes;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
            width:100%;
        }
    }
    .track-logo {
        width:100%;
    }
    .template {
        display:grid;
        place-items: center;
        position: relative;
        background-size: cover;
        background-position: center;
        &:before {      
            content:"";
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background: rgba(18,18,18,0.8)
        }
    }  
    .gallery {
        z-index: 1;
    }          
    .images {
        position: relative;
        top:0;
        left:0;
        width:100%;
        //height:100%;
        display:grid;
        grid-template-columns: 1;
        grid-auto-rows: 300px;
        //overflow: hidden;        
        @media (max-width:499px) {
            > div:first-child {
                display:none;
            }
        }
        @media (max-width:999px) {
            .title-slide {
                order:-1;
            }
        }
        @media (min-width:500px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width:850px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (min-width:1000px) {
            grid-template-columns: repeat(5, 1fr);
            grid-auto-rows:unset;
            grid-template-rows: repeat(2, calc(100% / 2));
            height:calc(100vh - 75px);
            &.more-people {
                grid-template-columns: repeat(7, 1fr);
                grid-template-rows: repeat(3, calc(100% / 2));
            }
        }
        > div {
            display:grid;
            place-items: center;
            padding:0 60px;
            box-sizing: border-box;
            text-align: right;     
            overflow: hidden;       
            .ui {
                justify-content:flex-end;
            }
        }
    }
    .info-tile {
        background:url('~@/assets/images/texture_black-asphalt.jpg');       
        background-size: cover;
        background-position: center; 
    }
    h1 {  
        font-family: 'Helvetica Neue LT Std';
        font-weight:900;
        position: relative;
        color: #F5F5F5;      
        text-transform: uppercase;
        padding:0;
        padding-bottom: 10px;
        font-size: 5.25rem;
        letter-spacing: 0;
        line-height:1;
    }
    .copy-slide {
        background: linear-gradient(90deg, #F3F2F2 0%, #ABA9A9 100%);
        letter-spacing: 0;
        color: #070707;
        font-size: 2rem;
        font-family: 'Helvetica Neue LT Std';
        font-weight:900;
        text-transform: uppercase;
        line-height: 1;
    } 
    .person-slide {   
        background-size: cover !important;  
        background: linear-gradient(270deg, #161616 0%, #070707 100%);         
        background-position: top center;
        position: relative;
        cursor:pointer;
        &:before {
            background-color: rgba(7,7,7,0.25);                        
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            pointer-events: none;
            content:"";
        }
        :deep() .person {
            pointer-events: none;
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            padding:36px;
            box-sizing: border-box;
            color: #F5F5F5;
            text-align: left;
            transition: opacity 1s ease-in-out;
            opacity: 0;
        }        
        &.active {
            :deep() .person-info {
                opacity: 0;
            }
            :deep() .person-details {
                opacity: 1;
                .email {
                    pointer-events: all !important;
                }
            }
            :deep() button svg{
                transform:rotateZ(0deg);
            }
        }
        :deep() .person-info {
            font-family: 'Helvetica Neue LT Std';   
            font-weight: 900;
            text-transform: uppercase;
            font-size: 1.5rem;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            opacity: 1;
            p {
                margin:0;
            }
            .last {
                color: #B6B6B6;
            }
            .department {
                font-size: 1rem;
                border-left:5px solid #F5F5F5;
                padding-left:10px;
                margin-top:10px;
                opacity: 1;
            }
        }
        :deep() .person-details {                      
            background-color: rgba(7,7,7,0.8);                        
            font-size: 1rem;            
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            p, a {
                margin:0;
            }
            .first { 
                font-family: 'Helvetica Neue LT Std';   
                font-weight: 900;
                text-transform: uppercase;
                font-size: 1.5rem;
                line-height: 1;
                margin-bottom:24px;                
                span {
                    color: #B6B6B6;
                }
            }
            .contact.email {
                display: block;
                color:inherit;
                font-size: 0.9375rem;
                text-transform: lowercase;
                overflow: hidden;
                text-overflow: ellipsis;
                pointer-events: none;
                text-decoration: none;
            }
            .contact {
                border-radius: 5px;
                background:rgba(#fff, 0.1);
                padding:16px;
                pointer-events:all;                
                &:not(:first-child) {
                    margin-top:1px;
                }
            }
        }
        :deep() button {
            pointer-events: none;
            border:none;
            display: grid;
            place-items: center;
            width:50px;
            aspect-ratio: 1;
            border-radius: 100%;
            position: absolute;
            z-index: 1;
            top:16px;
            right:12px;
            svg {
                transition: transform 0.2s ease-in-out;
                transform:rotateZ(45deg);
            }
        }
        > div {
            padding:36px;
            width: 100%;
            box-sizing: border-box;
            user-select: none;            
        }
        p {
            margin:0;
        }        
    }
    .image-slide {
        &:not(.person-slide) {
            background:url('~@/assets/images/texture_black-asphalt.jpg');       
            background-size: cover;
            background-position: center; 
        }        
    }
    .person-image-slide {
        position: relative;
        &:before {            
            content:"";
            background: linear-gradient(270deg, rgba(18,18,18,0) 0%, rgba(18,18,18,0.7) 100%);
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
        }
    }
</style>